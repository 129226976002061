import { Cloudinary } from "cloudinary-core";

import { isProdEnv, isStageEnv, cloudinaryCloudName } from "@/config";

const devDomain = "https://dev.atlasguru.io";
const stagingDomain = "https://stage.atlasguru.io";
const prodDomain = "https://www.atlasguru.com";

let staticPrefix = "";
const runningInAws = process.env.AWS_OR_LOCAL === "aws";
if (runningInAws && process.env.CDN_URL) {
  // We're running in Aws and a CDN_URL env var is present
  staticPrefix = process.env.CDN_URL;
}

// get seo cover photo
const cloudinary = Cloudinary.new({
  cloud_name: cloudinaryCloudName,
  secure: true,
});

const coverTransformations = {
  crop: "fill",
  gravity: "auto",
  quality: "auto:eco",
  fetchFormat: "jpg",
  height: 630,
  width: 1200,
};

const defaultCoverPhoto =
  "https://user-assets-cdn-assets-prod.atlasguru.com/tripPics/e229a070-a0e3-11e9-a65b-fdeb3015f929/1/trip-pic-1.b1c45fe91d654c6b24c5fe776ce367ec.jpg";

export const toRenderProps = (hoc) => {
  const RenderPropsComponent = (props) => props.children(props);
  return hoc(RenderPropsComponent);
};

export const staticUrl = (baseUrl = "") => {
  const srcUrl = baseUrl?.default?.src ?? baseUrl;

  if (srcUrl?.includes("https://")) {
    // If the base image path is a URL with a fully qualified domain name, then return it as is.
    // User-uploaded images, for example, will have a FQDN URL. We don't want to modify this.
    return srcUrl;
  }
  if (staticPrefix) {
    // If there is an env var that specifies a CDN URL, then append this to the image URL.
    // When the site is deployed in AWS Lambda, this env var will be set, and we'll use the CDN.
    // There are some adjustments to the URL that need to made first.
    return staticPrefix + srcUrl.replace("/static/.cdn/static", "/static");
  }

  // Otherwise, just return this base URL as is. This condition occurs when running locally.
  return srcUrl;
};

export const getCoverPhoto = (cloudId) => {
  const coverPath = cloudId
    ? cloudinary.url(cloudId, coverTransformations)
    : defaultCoverPhoto;
  const hasExtension = coverPath.match(/\.(jpg|jpeg|png|gif)$/i);
  const coverPhoto = hasExtension ? coverPath : `${coverPath}.jpg`;
  return coverPhoto;
};

// get region default image
// TODO: move region image's imports to constants file
export const getRegionCover = (regionName) => {
  switch (regionName) {
    case "Europe":
      return staticUrl(require("@/static/img/regions/europe-opt.jpg"));
    case "North America":
      return staticUrl(require("@/static/img/regions/north_america-opt.jpg"));
    case "South America":
      return staticUrl(require("@/static/img/regions/south_america-opt.jpg"));
    case "Africa":
      return staticUrl(require("@/static/img/regions/africa-opt.jpg"));
    case "Asia":
      return staticUrl(require("@/static/img/regions/asia-opt.jpg"));
    case "Oceania":
      return staticUrl(require("@/static/img/regions/oceania-opt.jpg"));
    default:
      return staticUrl(require("@/static/img/regions/all-opt.jpg"));
  }
};

// get flat locations photo list
export const getLocationsPhotos = (locations) => {
  if (!Array.isArray(locations)) return [];

  return locations.reduce((accu, locationVisited) => {
    const { name = "Location no specified", formatted_address } =
      locationVisited.location || {};
    const locationPhotos = locationVisited.tripPhotos || [];
    const labeledPhotos = locationPhotos.map((photo) => ({
      ...photo,
      location: name,
      country: (formatted_address || "").split(",").pop(),
    }));

    return [...accu, ...labeledPhotos];
  }, []);
};

// get hero photo metadata
export const getHeroPhotoMetadata = (locationsPhotos, mainPhotos) => {
  const tripPhotos = [...(locationsPhotos || []), ...(mainPhotos || [])];
  const photoMetadata =
    tripPhotos.filter(({ isHero }) => isHero)[0] || tripPhotos[0];
  return photoMetadata || {};
};

// TODO: create this utility and migrate all sections currntly using same logic
export const convertFromAwsToAlgolia = (data) => {
  return data;
};

// TODO: create this utility and migrate all sections currntly using same logic
export const convertFromAlgoliaToAws = (data) => {
  return data;
};

// convert plain algolia's data to be used on <Card info={data} />
export const convertItineraryToCardData = (data) => {
  const {
    cloudId = "",
    transformations,
    countries,
    itineraryId,
    numOfDays,
    // photoUrl,
    profilePicUrl,
    screenName,
    tripTitle,
    userId,
  } = data || {};
  const daysLegend = `${numOfDays || 1} day${numOfDays > 1 ? "s" : ""}`;
  const visitedLegend = countries || "Unknow locations";

  return {
    id: itineraryId,
    cardInfo: {
      cloudId,
      transformations,
      description: numOfDays && `${daysLegend} in ${visitedLegend}`,
      // photoUrl,
      title: tripTitle,
    },
    userInfo: {
      screenName,
      profilePicUrl,
      userId,
    },
  };
};

// object utis
export const deepEqual = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

// fills array with sequence of number
// (5) => [0,1,2,3,4]
export const fillArraySequence = (end) =>
  Array(end)
    .fill()
    .map((_, i) => i);

// string utils
export const alphaRegex = /[ A-Za-z]/;
export const numericRegex = /[0-9]/;
export const alphaNumericRegex = /[ A-Za-z0-9]/;

export const capitalizeFirstLetter = (str = "") => {
  if (typeof str !== "string") return str;
  const first = str.charAt(0);
  return first.match(/[a-z]/) ? `${first.toUpperCase()}${str.slice(1)}` : str;
};

export const trimText = (text, length = 70) => {
  if (!text) {
    return "";
  }

  return text.length >= length ? `${text.substr(0, length - 1)}...` : text;
};

export const removeExtraSpaces = (str = "") => {
  return str.replace(/\s{2,}/g, " ");
};

export const splitAt = (text, length = 40) => {
  if (!text) {
    return "";
  }

  return text.length >= length
    ? [text.slice(0, length), text.slice(length)]
    : text;
};

export const removeSpecialChars = (text) => {
  if (!text) {
    return "";
  }

  return text.replace(/[^a-zA-ZÀ-úĄ-Ż0-9~!@#$%^&*()_|+\-=?;:'",.<> ]/gi, "");
};

export const satinizeString = (str, maxWordLegth) => {
  if (!str) return "";

  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9- ]/g, "_") // replace any special character by _
    .replace(/^ +/, "") // remove any whitespace at beginning
    .split(" ")
    .slice(0, maxWordLegth)
    .join("-"); // trim to amount and separate by dashes
};

// https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
export const slugify = (string = "") => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return (
    (string || "")
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      // eslint-disable-next-line no-useless-escape
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
};

// removes falsy values and metadata
export const removeEmpty = (obj) => {
  // return if obj is invalid
  if (!obj) return {};

  const o = JSON.parse(JSON.stringify(obj));

  Object.keys(o).forEach((key) => {
    /* eslint-disable */
    if (o.__typename) {
      delete o.__typename;
    }
    /* eslint-enable */
    if (o[key] && typeof o[key] === "object") o[key] = removeEmpty(o[key]);
    else if (Array.isArray(o[key])) o[key] = o[key].map((v) => removeEmpty(v));
    else if (o[key] === "" || o[key] === null || typeof o[key] === "undefined")
      delete o[key];
    // eslint-disable-next-line no-self-assign
    else o[key] = o[key];
  });

  return o;
};

export function checkAlphaNumericKeys(keyCode) {
  const inp = String.fromCharCode(keyCode);

  return alphaNumericRegex.test(inp);
}

export function validURL(str) {
  const pattern = new RegExp(
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );

  return !!pattern.test(str);
}

export function sanitizeUrl(url) {
  // Define an array of common subdomains to exclude
  const commonSubdomains = ["www", "blog", "forums"];

  // remove web protocol (http:// or https://).
  let withoutProtocol = (url || "").replace(/^(https?:\/\/)/, "");

  // remove common subdomains
  commonSubdomains.forEach((subdomain) => {
    const regex = new RegExp(`^${subdomain}\\.`);
    withoutProtocol = withoutProtocol.replace(regex, "");
  });

  // remove final backslash
  const withoutFinalSlash = withoutProtocol.replace(/\/$/, "");

  return withoutFinalSlash;
}

export function isEmail(str) {
  const pattern = new RegExp(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
  );

  return !!pattern.test(str);
}

export function getSafeUrl(
  url = "https://url.is.not.defined/",
  ensureTrailingSlash = true
) {
  const tempUrl =
    url.substr(url.length - 1) === "/" ? url.substr(0, url.length - 1) : url;
  return ensureTrailingSlash ? `${tempUrl}/` : tempUrl;
}

export function getDomainFromUrl(url) {
  try {
    // Using URL constructor for modern browsers (recommended)
    const newUrl = new URL(url);
    return newUrl.hostname;
  } catch (error) {
    // Fallback for older browsers or invalid URLs
    const parts = url.split("//");
    if (parts.length !== 2) {
      return null; // Not a valid URL format
    }
    const domainPart = parts[1].split("/");
    return domainPart[0];
  }
}

export function getRandomNumbersString(length = 5, max = 9) {
  return Array.from({ length }, () => Math.floor(Math.random() * max)).join("");
}

export function parseParams(asPath) {
  const querystring = asPath.replace("/?", "");
  const query = Object.fromEntries(new URLSearchParams(querystring));
  return query;
}

export function getAnalyticsParams(query = {}) {
  return {
    campaign: query.campaign,
    campaignid: query.campaignid,
    adgroupid: query.adgroupid,
    utmCampaign: query.utm_campaign,
    utmSource: query.utm_source,
    utmMedium: query.utm_medium,
    utmContent: query.utm_content,
    utmTerm: query.utm_term && decodeURI(query.utm_term),
  };
}

export function getWindowDimensions() {
  const isBrowser = typeof window !== "undefined";
  const container = isBrowser ? window : {};
  const { innerWidth = 0, innerHeight = 0 } = container;
  return {
    width: innerWidth,
    height: innerHeight,
  };
}

export const getEnvDomain = () => {
  if (isProdEnv) {
    return prodDomain;
  } else if (isStageEnv) {
    return stagingDomain;
  } else {
    return devDomain;
  }
};

export const getCanonicalUrl = (routePath = "") => {
  const domain = getEnvDomain();

  let [path = "", query = ""] = routePath?.split("?") || [];

  if (path.slice(-1) !== "/") {
    path = path + "/";
  }

  // normally, we don't need the query string and it may create duplicates
  // with the exception of itineraries and results where it's used for pagination
  // to show diferent content
  if (
    query &&
    (path.startsWith("/itineraries") || path.startsWith("/results"))
  ) {
    return domain + path + "?" + query;
  }

  return domain + path;
};

export const checkLinkIsExternal = (url) =>
  url &&
  !url.startsWith(prodDomain) &&
  !url.startsWith(stagingDomain) &&
  !url.startsWith(devDomain);

export const needsPageLoader = (url) => {
  return url?.length > 0 && !url.startsWith("/itinerary");
};
