export const nodeEnv = process.env.NODE_ENV;
export const isProdBuild = nodeEnv === "production";
export const stackName = process.env.STACK_NAME;
export const isDevEnv = stackName === "site-build-dev" || !isProdBuild;
export const isStageEnv = stackName === "site-build-master";
export const isProdEnv = !isDevEnv && !isStageEnv;
export const awsOrLocal = process.env.AWS_OR_LOCAL;
export const runningLocally = !("LAMBDA_TASK_ROOT" in process.env);
export const publicApiEndpoint = process.env.PUBLIC_API_ENDPOINT;
export const publicApiEndpointCached = process.env.PUBLIC_API_ENDPOINT_CACHED;
export const protectedApiEndpoint = process.env.PROTECTED_API_ENDPOINT;
export const userPoolWebClientId = process.env.USER_POOL_CLIENT_ID;
export const region = process.env.REGION;
export const userPoolId = process.env.USER_POOL_ID;
export const identityPoolId = process.env.IDENTITY_POOL_ID;
export const cognitoOauthDomain = process.env.COGNITO_OAUTH_DOMAIN;
export const assetsEndpoint = process.env.CDN_URL;
export const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY;
export const googleAnalyticsKey = process.env.GOOGLE_ANALYTICS_KEY;
export const segmentKey = process.env.SEGMENT_KEY;
export const algoliaId = process.env.ALGOLIA_APP_ID;
export const algoliaSearchOnlyAPIKey = process.env.ALGOLIA_SEARCH_ONLY_API_KEY;
export const algoliaIndexNameItinerary =
  process.env.ALGOLIA_INDEX_NAME_ITINERARY;
export const algoliaIndexNameTripHelp =
  process.env.ALGOLIA_INDEX_NAME_TRIP_HELP;
export const algoliaIndexNameTopics = process.env.ALGOLIA_INDEX_NAME_TOPICS;
// export const googleTagManagerKey = process.env.GOOGLE_TAG_MANAGER_KEY;
export const displayAds = process.env.DISPLAY_ADS;
export const disabledWipFeatures = process.env.DISABLE_WIP_FEATURES === "true";
export const commitId = process.env.COMMIT_ID;
export const amplitudeKey = process.env.AMPLITUDE_KEY;
export const cloudinaryCloudName = process.env.CLOUDINARY_CLOUD_NAME;
export const cloudinaryPhotoPreset = "travelPhotosPreset";
export const cloudinaryVideoPreset = "travelVideoPreset";
export const cloudinaryItinerariesPath =
  process.env.CLOUDINARY_ITINERARIES_PATH;
export const cloudinaryProfilesPath = process.env.CLOUDINARY_PROFILES_PATH;
export const cachedAPIEnabled = true;
// export const cloudinaryApiKey = process.env.CLOUDINARY_API_KEY;
// export const cloudinaryApiSecret = process.env.CLOUDINARY_API_SECRET;
export const gtpNetworkId = "22468637909";
export const getYourGuidePartnerId = "GNA07G5";
export const areAiItinerariesRestricted = false;
