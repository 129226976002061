import { staticUrl } from "./utils";

// Region SVG images components
import MapAsia from "../components/ui/regions/MapAsia";
import MapEurope from "../components/ui/regions/MapEurope";
import MapNorthAmerica from "../components/ui/regions/MapNorthAmerica";
import MapSouthAmerica from "../components/ui/regions/MapSouthAmerica";
import MapAfrica from "../components/ui/regions/MapAfrica";
import MapOceania from "../components/ui/regions/MapOceania";

// Google Maps
export const GMAP_MIN_ZOOM = 3;
export const GMAP_MAX_ZOOM = 15;

// Pages
export const PAGE_SIZE = 18;

// Search
export const SEARCH_DEBOUNCE_TIME = 700;

// Placeholders & defaults
export const PLACEHOLDER_AVATAR = staticUrl(
  require("@/static/img/avatar-placeholder-opt.jpg")
);

export const CUSTOM_CROP_TRANSFORMATIONS = {
  crop: "crop",
  gravity: "custom",
};

export const DEFAULT_TRANSFORMATIONS = {
  clientHints: true,
  crop: "limit",
  dpr: "auto",
  fetchFormat: "auto",
  flags: "progressive",
  quality: "auto:eco",
  startOffset: 1.5,
  height: 1500,
  width: 2500,
};

export const PLACEHOLDER_4x3 = "https://via.placeholder.com/430x280/888/666";

export const GMAP_SEARCH_URL =
  "https://www.google.com/maps/search/?api=1&query=";

// social links
export const ATLASGURU_DOMAIN = "https://www.atlasguru.com";
export const FACEBOOK_URL =
  "https://www.facebook.com/Atlas-Guru-2012086725486339/";
export const INSTAGRAM_URL = "https://www.instagram.com/atlasguru/";
export const PINTEREST_URL = "https://www.pinterest.com/atlasguru/";

// metadata
// default atlasguru cover picture
export const DEFAULT_COVER_PHOTO =
  "https://user-assets-cdn-assets-prod.atlasguru.com/tripPics/e229a070-a0e3-11e9-a65b-fdeb3015f929/1/trip-pic-1.b1c45fe91d654c6b24c5fe776ce367ec.jpg";

// Indexes
export const MONTHS = [
  { label: "January", abbr: "Jan", value: 1 },
  { label: "February", abbr: "Feb", value: 2 },
  { label: "March", abbr: "Mar", value: 3 },
  { label: "April", abbr: "Apr", value: 4 },
  { label: "May", abbr: "May", value: 5 },
  { label: "June", abbr: "Jun", value: 6 },
  { label: "July", abbr: "Jul", value: 7 },
  { label: "August", abbr: "Aug", value: 8 },
  { label: "September", abbr: "Sep", value: 9 },
  { label: "October", abbr: "Oct", value: 10 },
  { label: "November", abbr: "Nov", value: 11 },
  { label: "December", abbr: "Dec", value: 12 },
];

export const TOPICS = [
  { index: "honeymoon", text: "Honeymoon" },
  { index: "outdoor-active", text: "Outdoor/Active" },
  { index: "family-travel", text: "Family Travel" },
  { index: "food", text: "Food" },
  { index: "transportation", text: "Transportation" },
  { index: "safety", text: "Safety" },
  { index: "solo-female-travel", text: "Solo Female Travel" },
  { index: "backpacking", text: "Backpacking" },
  { index: "packing", text: "Packing" },
  { index: "budget-travel", text: "Budget Travel" },
  { index: "accommodations", text: "Accommodations" },
  { index: "restaurants", text: "Restaurants" },
];

export const REGION_MAP = {
  europe: {
    text: "Europe",
    icon: MapEurope,
  },
  "north-america": {
    text: "North America",
    icon: MapNorthAmerica,
  },
  "south-america": {
    text: "South America",
    icon: MapSouthAmerica,
  },
  africa: {
    text: "Africa",
    icon: MapAfrica,
  },
  asia: {
    text: "Asia",
    icon: MapAsia,
  },
  oceania: {
    text: "Oceania",
    icon: MapOceania,
  },
};

// profile cover images
export const COVER_PHOTOS = [
  {
    id: 1,
    url: staticUrl(require("../static/img/covers/cover-image-1.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-1_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 2,
    url: staticUrl(require("../static/img/covers/cover-image-2.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-2_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 3,
    url: staticUrl(require("../static/img/covers/cover-image-3.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-3_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 4,
    url: staticUrl(require("../static/img/covers/cover-image-4.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-4_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 5,
    url: staticUrl(require("../static/img/covers/cover-image-5.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-5_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 6,
    url: staticUrl(require("../static/img/covers/cover-image-6.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-6_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 7,
    url: staticUrl(require("../static/img/covers/cover-image-7.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-7_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 8,
    url: staticUrl(require("../static/img/covers/cover-image-8.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-8_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 9,
    url: staticUrl(require("../static/img/covers/cover-image-9.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-9_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 10,
    url: staticUrl(require("../static/img/covers/cover-image-10.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-10_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 11,
    url: staticUrl(require("../static/img/covers/cover-image-11.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-11_thumb.jpg")),
    alt: "Cover Image",
  },
  {
    id: 12,
    url: staticUrl(require("../static/img/covers/cover-image-12.jpg")),
    thumb: staticUrl(require("../static/img/covers/cover-image-12_thumb.jpg")),
    alt: "Cover Image",
  },
];

// Default media components
export const DEFAULT_COVER_IMAGE_ID = 5;

// Default messages
export const DEFAULT_PROFILE_SUCCESS_MESSAGE =
  "Profile was successfully updated";
export const DEFAULT_PROFILE_ERROR_MESSAGE =
  "There was an error updating your profile";
export const DEFAULT_UNTITLE_TRIP_MESSAGE = "Untitled trip report";
export const DEFAULT_DESCRIPTION_MESSAGE = "";
export const DEFAULT_ITINERARY_INCOMPLETE_MESSAGE =
  "Click here to complete this trip report";
export const DEFAULT_ITINERARIES_EMPTY_MESSAGE =
  "Uh oh. Looks like you don’t have any trip reports.";
export const DEFAULT_TRIP_HELP_EMPTY_MESSAGE =
  "Looks like you don’t have any questions posted yet.";
export const DEFAULT_REDIRECT_MESSAGE = "Explore more here";
export const DEFAULT_IMAGE_CAPTION =
  "AtlasGuru Trip from travelers by travelers";
export const DEFAULT_SCREENNAME_TAKEN =
  "already exists. Please use a different Screen Name";
export const DEFAULT_PASSWORD_ERROR =
  "There was an error updating your password";

// TODO: Remove this QANDAS variable and move logic around to QUESTIONS_QUERY_NO_CACHE
export const QANDAS = [
  {
    qid: 1,
    egText: "E.g. Were you there for the right amount of time? Cities to skip?",
    question: "What would you have changed?",
    sortOrder: 1,
  },
  {
    qid: 2,
    question: "Tips you would give a friend?",
    sortOrder: 4,
  },
  {
    qid: 3,
    question: "Transportation Tips?",
    sortOrder: 6,
  },
  {
    qid: 7,
    question: "Anything go wrong during the trip?",
    sortOrder: 2,
  },
  {
    qid: 8,
    question: "Packing tips?",
    sortOrder: 5,
  },
  {
    qid: 9,
    question: "Restaurant recommendations?",
    sortOrder: 3,
  },
  {
    qid: 10,
    question: "Any surprises?",
    sortOrder: 7,
  },
  {
    qid: 11,
    egText: "E.g. Travel Agency used, better rate via booking direct?",
    question: "Booking details?",
    sortOrder: 8,
  },
];

// Explore Itineraries SEO Metadata
export const EXPLORE_ITINERARIES_METADATA = {
  europe: {
    title: "European Travel Itinerary | Resources & Inspiration | AtlasGuru",
    keywords:
      "europe travel itinerary, european trips, europe trip ideas, europe itinerary",
    description:
      "European travel itinerary ideas for your next vacation. Learn tips and tricks from our community of world travelers. Find inspiration on AtlasGuru!",
    heading: "Travel Itinerary Ideas",
    subheading: "For Europe",
  },
  "north-america": {
    title:
      "North America Travel Itinerary | Resources & Inspiration | AtlasGuru",
    keywords:
      "north america itinerary, usa travel itinerary, travel north america, us trip ideas",
    description:
      "North America travel itinerary ideas for your next vacation. Learn tips and tricks from our community of world travelers. Find inspiration on AtlasGuru!",
    heading: "Travel Itinerary Ideas",
    subheading: "For USA, Canada, Mexico, Central America and Caribbean",
  },
  "south-america": {
    title:
      "South America Travel Itinerary | Resources & Inspiration | AtlasGuru",
    keywords:
      "travel south america, south america itinerary, places to visit in south america, hiking in south america",
    description:
      "South America travel itinerary ideas for your next vacation. Learn tips and tricks from our community of world travelers. Find inspiration on AtlasGuru!",
    heading: "Travel Itinerary Ideas",
    subheading: "For South America",
  },
  africa: {
    title: "Africa Travel Itinerary | Resources & Inspiration | AtlasGuru",
    keywords:
      "africa travel resources, africa travel destinations, south africa itinerary, african travels",
    description:
      "Africa travel itinerary ideas for your next vacation. Learn tips and tricks from our community of world travelers. Find inspiration on AtlasGuru!",
    heading: "Travel Itinerary Ideas",
    subheading: "For Africa",
  },
  asia: {
    title:
      "Southeast Asia Travel Itinerary | Resources & Inspiration | AtlasGuru",
    keywords: "southeast asia itinerary, southeast asia travel, travel to asia",
    description:
      "Southeast Asia travel itinerary ideas for your next vacation. Learn tips and tricks from our community of world travelers. Find inspiration on AtlasGuru!",
    heading: "Travel Itinerary Ideas",
    subheading: "For Asia including Southeast Asia",
  },
  oceania: {
    title:
      "Australia & New Zealand Travel Itinerary | Resources & Inspiration | AtlasGuru",
    keywords:
      "new zealand and australia, new zealand itinerary, travel itinerary new zealand, australia itinerary",
    description:
      "Australia and New Zealand travel itinerary ideas for your next vacation. Learn tips and tricks from our community of world travelers. Find inspiration on AtlasGuru!",
    heading: "Travel Itinerary Ideas",
    subheading: "For Oceania including Australia and New Zealand",
  },
  default: {
    title: "Travel Inspiration & Trip Itineraries | AtlasGuru",
    keywords: "travel inspiration, trip itineraries, travel itineraries",
    description:
      "AtlasGuru has hundreds of new travel itineraries across the world to give you travel inspiration for your perfect vacation.  Explore our writers' itineraries!",
    heading: "Explore Trip Itineraries and Travel Inspiration",
  },
};

export const SESSION_ITINERARIES_STORAGE_KEY = "sessionItineraries";
