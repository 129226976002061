import { useEffect } from "react";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { AnalyticsProvider } from "use-analytics";
import NProgress from "nprogress";
// app
import { UserProvider } from "@auth0/nextjs-auth0";
import { AdsProvider } from "@/lib/ads";
import { DEFAULT_COVER_PHOTO } from "@/lib/constants";
import { needsPageLoader, getCanonicalUrl, staticUrl } from "@/lib/utils";
import { useApollo } from "@/lib/apolloClient";
import { analytics } from "@/lib/AtlasAnalytics";
import theme from "@/lib/theme";

Router.events.on(
  "routeChangeStart",
  (url) => needsPageLoader(url) && NProgress.start()
);
Router.events.on(
  "routeChangeComplete",
  (url) => needsPageLoader(url) && NProgress.done()
);
Router.events.on(
  "routeChangeError",
  (url) => needsPageLoader(url) && NProgress.done()
);

const App = (props) => {
  const { Component, pageProps } = props;
  const apolloClient = useApollo(pageProps.initialApolloState);

  const router = useRouter();
  const canonicalUrl = getCanonicalUrl(router.asPath);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <title key="title">AtlasGuru</title>
        <meta
          name="description"
          content="AtlasGuru is a fresh new travel content platform where real people share their travel adventures, stories and itineraries to help others plan their dream trip."
          key="description"
        />
        <meta name="image" content={DEFAULT_COVER_PHOTO} key="image" />
        <meta
          name="robots"
          key="robots:maxImagePreview"
          content="max-image-preview:large"
        />
        {/* https://developer.mozilla.org/en-US/docs/Web/HTTP/Client_hints */}
        <meta httpEquiv="Accept-CH" content="DPR" />
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <meta
          name="viewport"
          key="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=5"
        />
        <meta property="fb:admins" content="2012086725486339" key="fb:admins" />
        <meta
          name="p:domain_verify"
          content="41a5520c11c047968d8fe7e4762dd1d0"
          key="p:domain_verify"
        />

        {/* Schema.org for Google */}
        <meta itemProp="name" content="AtlasGuru" key="itemProp:name" />
        <meta
          itemProp="description"
          key="itemProp:description"
          content="Itineraries by travelers for  travelers"
        />
        <meta
          itemProp="image"
          key="itemProp:image"
          content={DEFAULT_COVER_PHOTO}
        />

        {/* Open Graph general (Facebook, Pinterest & Google+) */}
        <meta property="og:title" key="og:title" content="AtlasGuru" />
        <meta
          property="og:description"
          key="og:description"
          content="Itineraries by travelers for  travelers"
        />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.atlasguru.com"
        />
        <meta
          property="og:site_name"
          key="og:site_name"
          content="AtlasGuru - Itineraries by travelers for travelers"
        />
        <meta
          property="og:image"
          key="og:image"
          content={DEFAULT_COVER_PHOTO}
        />
        <meta property="og:image:width" content="700" key="og:image:width" />
        <meta property="og:image:height" content="525" key="og:image:height" />
        <meta property="og:type" content="website" key="og:type" />

        <link rel="canonical" key="canonical" href={canonicalUrl} />
        <link
          rel="icon"
          type="image/x-icon"
          href={staticUrl(require("../static/favicon.ico"))}
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <StylesProvider>
            <CssBaseline />
            <AnalyticsProvider instance={analytics}>
              <UserProvider>
                <AdsProvider>
                  <Component {...pageProps} />
                </AdsProvider>
              </UserProvider>
            </AnalyticsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
