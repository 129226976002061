/* eslint-disable no-console */

import { gtpNetworkId } from "@/config";
import { isProdEnv } from "@/config";

const AG_UNIT_ROOT = "atlas-guru";

// debugger
// eslint-disable-next-line no-unused-vars
const trace = (...args) => {
  // console.log("useAds::", ...args);
};

// push a method to the initialize library array
export const cmdPush = (cb = () => {}) => {
  window.googletag = window.googletag || { cmd: [] };
  const { googletag } = window;

  try {
    // set adsense into development mode
    // https://developers.google.com/publisher-tag/adsense_attributes
    if (!isProdEnv) {
      googletag.pubads().set("adsense_test_mode", "on");
    }

    const cmdCounter = googletag.cmd.push(cb);
    trace("cmdPush", cmdCounter, window.googletag.cmd);

    return cmdCounter;
  } catch (error) {
    trace(error);
  }
};

// disable initial ads render: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_disableInitialLoad
export const disableInitialLoad = () => {
  const { googletag } = window;
  trace("disableInitialLoad");
  googletag.pubads().disableInitialLoad();
  googletag.pubads().enableLazyLoad();
};

export const enableLazyLoad = () => {
  const { googletag } = window;
  googletag.pubads().enableLazyLoad();
};

// enable single request: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_enableSingleRequest
export const enableSingleRequest = () => {
  const { googletag } = window;
  trace("enableSingleRequest");
  googletag.pubads().enableSingleRequest();
};

// add event listener: https://developers.google.com/publisher-tag/reference#googletag.Service_addEventListener
export const addEvent = (...params) => {
  const { googletag } = window;
  trace("addEvent", params);
  googletag.pubads().addEventListener(...params);
};

// set targeting: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_setTargeting
export const setTargeting = (key = "", value = "") => {
  const { googletag } = window;
  googletag.pubads().setTargeting(key, value);
  trace("setTargeting: ", key, value, googletag.pubads().getTargetingKeys());
};

// clear targeting: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_clearTargeting
export const clearTargeting = () => {
  const { googletag } = window;
  trace("clearTargeting");
  if (googletag?.pubads) {
    googletag.pubads().clearTargeting();
  }
};

// remove ad slot: https://developers.google.com/publisher-tag/reference#googletag.destroySlots
export const destroySlots = function (slotsArr) {
  const { googletag } = window;
  let isDestroyed = false;

  trace("destroySlots:: ", slotsArr);

  try {
    isDestroyed = googletag.destroySlots(slotsArr);
  } catch (error) {
    trace(error);
  }

  return isDestroyed;
};

//define slot: https://developers.google.com/publisher-tag/reference#googletag.defineSlot
const define = (name = "", sizes = [], id = "", params = {}) => {
  const { googletag } = window;
  const { targeting = {} } = params;
  const slotTargetingKeys =
    typeof targeting === "object" ? Object.keys(targeting) : [];

  try {
    // create slot
    const slot = googletag.defineSlot(
      `/${gtpNetworkId}/${AG_UNIT_ROOT}${name}`,
      sizes,
      id
    );

    // set slot level targeting
    // https://developers.google.com/publisher-tag/common_implementation_mistakes#scenario-5:-mis-ordering-calls-to-gpt
    if (slotTargetingKeys.length > 0) {
      slotTargetingKeys.forEach((key) => {
        slot?.setTargeting(key, targeting[key]);
      });
    }

    const slotService = slot?.addService(googletag.pubads());
    return slotService;
  } catch (error) {
    trace(error);
  } finally {
  }
};

export const defineSlot = (...params) => {
  let slot = null;

  if (window) {
    try {
      slot = define(...params);
    } catch (error) {
      trace(error);
      destroySlots([slot]);
      slot = define(...params);
    }
  }

  trace("defineSlot", params);

  return slot;
};

// set responsive sizes: https://developers.google.com/publisher-tag/reference#googletag.SizeMappingBuilder_addSize
const createSizes = (adSlot, sizes) => {
  const { googletag } = window;

  // responsive ads depend on viewport size
  // see: https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads
  const mapping = googletag
    .sizeMapping()
    .addSize(sizes.lg[0], sizes.lg[1])
    .addSize(sizes.md[0], sizes.md[1])
    .addSize(sizes.sm[0], sizes.sm[1])
    .addSize([0, 0], [])
    .build();

  // Throws an error when sizes are not pipped
  // const mapping = googletag.sizeMapping();
  // Object.values(sizes).forEach((size) => mapping.addSize(size[0], size[1]));
  // mapping.addSize([0, 0], []);
  // mapping.build();

  adSlot.defineSizeMapping(mapping);

  return adSlot;
};

export const defineSizeMapping = (...params) => {
  let slot = null;

  if (window) {
    try {
      slot = createSizes(...params);
    } catch (error) {
      trace(error);
      destroySlots([slot]);
      slot = createSizes(...params);
    }
  }

  trace("defineSizeMapping", slot, params);

  return slot;
};

// refresh ad: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_refresh
export const refresh = (...params) => {
  const { googletag } = window;

  try {
    googletag.pubads().refresh(...params);

    trace(
      "refresh",
      window.googletag.pubads().getTargetingKeys(),
      " --- ",
      window.googletag.pubads().getSlots()
    );
  } catch (error) {
    trace(error);
  }
};

// display ad: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_display
const render = (adSlot) => {
  const { googletag } = window;
  // const targetingKeys = googletag.pubads().getTargetingKeys();

  if (adSlot) {
    // googletag.pubads().collapseEmptyDivs();
    // googletag.pubads().enableSingleRequest();

    // disable initial load when there are global targets defined
    // this is a workaround to disable display and refresh all ads from
    // the useGPTPage hook when we use page targeting to load ads per page
    // this hack avoid the ads to rerender when the page hook register the target
    // https://developers.google.com/publisher-tag/guides/control-ad-loading
    // if (targetingKeys.length > 0) {
    //   googletag.pubads().disableInitialLoad();
    // }

    googletag.enableServices();
    googletag.display(adSlot);
  }
};

export const display = (adSlot = null) => {
  try {
    render(adSlot);
  } catch (error) {
    trace(error);
    // destroySlots([adSlot]);
    // render(adSlot);
  }

  trace(
    "display",
    adSlot.getAdUnitPath(),
    " --- ",
    adSlot.getSlotElementId(),
    " --- ",
    window.googletag.pubads().getTargetingKeys()
  );
};
