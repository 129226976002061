import Analytics from "analytics";
import gtmPlugin from "@analytics/google-tag-manager";
import { isProdBuild, amplitudeKey, googleAnalyticsKey } from "../config";

/* Initialize analytics & load plugins */
export const analytics = Analytics({
  app: "atlasguru",
  debug: !isProdBuild,
  plugins: [
    gtmPlugin({
      containerId: googleAnalyticsKey,
    }),
  ],
});

/*
  TODO:
  Remove class type Analytics in order to use the @analytics/hook and
  integrate everything as a plugin into the config above
  follow progress of the Amplitude hook on this pull request:
  https://github.com/DavidWells/analytics/pull/19
*/
export default class AtlasAnalytics {
  constructor() {
    this.amplitudeKey = amplitudeKey;
    this.amplitude = this.initAmplitude();
  }

  initAmplitude() {
    let amplitude = null;
    if (typeof window !== "undefined") {
      amplitude = require("amplitude-js/amplitude");
      // do not block main thread
      setTimeout(() => {
        amplitude.getInstance().init(this.amplitudeKey);
      }, 100);
    }
    return amplitude;
  }

  fireAmplitudeEvent(eventName, eventProperties) {
    if (this.amplitude) {
      setTimeout(() => {
        this.amplitude.getInstance().logEvent(eventName, eventProperties);
        analytics.track(eventName, eventProperties);
      }, 100);
    }
  }

  /**
   * This event occurs after a user creates and an account, prior to verification
   */
  submitAccountInfo(eventProperties) {
    this.fireAmplitudeEvent("Submits Account Info", eventProperties);
  }

  verifiesAccount(eventProperties) {
    this.fireAmplitudeEvent("Verifies Account", eventProperties);
  }

  updateAccount(eventProperties) {
    this.fireAmplitudeEvent("Updates Account", eventProperties);
  }

  saveTripReport(sectionName, eventProperties) {
    this.fireAmplitudeEvent(
      `Saves Trip Report ${sectionName}`,
      eventProperties
    );
  }

  publishTripReport({ itineraryId, tripTitle }) {
    this.fireAmplitudeEvent("Publishes Trip Report", {
      itineraryId,
      itineraryTitle: tripTitle,
    });
  }

  unpublishTripReport({ itineraryId, tripTitle }) {
    this.fireAmplitudeEvent("Unpublishes Trip Report", {
      itineraryId,
      itineraryTitle: tripTitle,
    });
  }

  submitItinerarySearch(itinerary) {
    const eventProperties = {
      itineraryId: itinerary.itineraryId,
      itineraryUrl: itinerary.url,
      searchTerm: itinerary.searchTerm,
      countries: itinerary.countries.split(","),
      itineraryTitle: itinerary.tripTitle,
      region: itinerary.region,
    };
    this.fireAmplitudeEvent("Submits Itineraries Search", eventProperties);
  }

  searchResultNotFound(searchTerm) {
    this.fireAmplitudeEvent("Searches Without Results", { searchTerm });
  }

  newsletterSignUp(eventProperties) {
    this.fireAmplitudeEvent("Signups Newsletter", eventProperties);
  }

  viewItineraryPage(data) {
    const { countries, locationsVisited, regionName, tripTitle, itineraryId } =
      data;
    // eslint-disable-next-line
    // console.log(`In viewItineraryPage: ${JSON.stringify(itinerary)}`);
    let eventProperties;

    // If we run into an error parsing the itinerary location, we'll continue without event properites.
    // We don't want crash the user experience if there is bug related to analytics.
    try {
      const countriesNames = countries.map((country) => country.name);
      const cities = locationsVisited.map(
        (locationVisited) => locationVisited.location.name
      );
      const formattedAddresses = locationsVisited.map(
        (locationVisited) => locationVisited.location.formatted_address
      );
      eventProperties = {
        region: regionName,
        cities,
        countries: countriesNames,
        itineraryTitle: tripTitle,
        itineraryId,
        formattedAddresses,
      };
    } catch (e) {}

    // If we run into an error firing the Amplitude event, then we don't want to crash the user experience.
    try {
      this.fireAmplitudeEvent("Views Itinerary Page", eventProperties);
    } catch (e) {}
  }
}
