// extract jwt token from cookies either server or client side
export const getToken = async (ctx) => {
  // it's server side
  if (ctx?.req?.cookies) {
    return ctx.req.cookies?.refId;
  }
  // client
  const match = document.cookie.match(new RegExp("(^| )refId=([^;]+)"));
  return match?.[2] ?? null;
};

export const namespaceAG = "https://atlasguru.com";

export const getAuthClaim = (user, claim) => {
  return user?.[`${namespaceAG}/${claim}`];
};

export const deleteCognitoCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    if (name.trim().startsWith("CognitoIdentityServiceProvider")) {
      document.cookie =
        name +
        "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" +
        window.location.hostname;
    }
  }
};
