import { useMemo } from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from,
  split,
} from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry";
import { sha256 } from "crypto-hash";
// app
import { getToken } from "./auth";
import {
  publicApiEndpointCached,
  // protectedApiEndpoint,
  publicApiEndpoint,
  cachedAPIEnabled,
} from "@/config";

const endpointsCached = {
  user: `${publicApiEndpointCached}cached/user/`,
  itinerary: `${publicApiEndpointCached}cached/itinerary/`,
  topic: `${publicApiEndpointCached}cached/topic/`,
  homepage: `${publicApiEndpointCached}cached/homepage/`,
};

let apolloClient;

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (count > 5) {
      return false;
    }
    return !!error && !operation.operationName?.includes("AI_ITINERARY");
  },
  delay: (count) => {
    return count * 1000;
  },
});

const httpLink = ApolloLink.from([
  retryLink,
  new HttpLink({
    uri: publicApiEndpointCached,
    ssrMode: typeof window === "undefined",
  }),
]);

const persistentLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
}).concat(httpLink);

// TODO: create query->endpoint mapping, to easily add cached api routes
const authMiddleware = (context) =>
  new ApolloLink(async (operation, forward) => {
    const token = await getToken(context);
    const operationName = operation?.operationName ?? "";
    const isMutation = operation?.query?.loc?.source?.body
      .toLocaleLowerCase()
      .trim()
      .startsWith("mutation");

    if (isMutation) {
      operation.setContext({
        uri: publicApiEndpoint,
        headers: {
          Authorization: token,
        },
      });
      return forward(operation);
    }

    if (!cachedAPIEnabled || operationName.includes("NO_CACHE")) {
      operation.setContext({
        uri: publicApiEndpoint,
      });
      return forward(operation);
    }

    if (operationName === "USER_QUERY" || operationName === "PROFILE_QUERY") {
      operation.setContext({
        uri: `${endpointsCached.user}${operation.variables.userId}/`,
      });
      return forward(operation);
    }

    if (operationName === "ITINERARY_QUERY") {
      operation.setContext({
        uri: `${endpointsCached.itinerary}${operation.variables.id}/`,
      });
      return forward(operation);
    }

    if (operationName === "TOPIC_QUERY") {
      operation.setContext({
        uri: `${endpointsCached.topic}${operation.variables.topicId}/`,
      });
      return forward(operation);
    }

    if (operationName === "HOMEPAGE_QUERY") {
      operation.setContext({
        uri: `${endpointsCached.homepage}`,
      });
      return forward(operation);
    }

    return forward(operation);
  });

const directionalLink = split(
  (operation) => operation.operationName?.includes("NO_CACHE"),
  httpLink,
  persistentLink
);

const createApolloClient = (context) => {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([
      authMiddleware(context),
      cachedAPIEnabled ? directionalLink : httpLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            User: {
              merge(existing, incoming) {
                return { ...existing, ...incoming };
              },
            },
            Itineraries: {
              merge(_, incoming) {
                return incoming;
              },
            },
            Itinerary: {
              merge(_, incoming) {
                return incoming;
              },
            },
            ItineraryPreview: {
              merge(_, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
  });
};

export function initializeApollo({ initialState = null, context = null } = {}) {
  const client = apolloClient ?? createApolloClient(context);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    client.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return client;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = client;

  return client;
}

export function useApollo(initialState) {
  const store = useMemo(
    () => initializeApollo({ initialState }),
    [initialState]
  );
  return store;
}
