import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";

import { gtpNetworkId } from "@/config";
import {
  cmdPush,
  display,
  refresh,
  addEvent,
  defineSlot,
  destroySlots,
  setTargeting,
  clearTargeting,
  defineSizeMapping,
  disableInitialLoad,
  enableSingleRequest,
  enableLazyLoad,
} from "@/lib/googletag";
import useRouteLoading from "@/lib/useRouteLoading";

const AdsContext = createContext();

// debugger
// eslint-disable-next-line no-unused-vars
const trace = (...args) => {
  // console.log("useAds::", ...args);
};

const AdsProvider = ({ children }) => {
  const [gptHasLoaded, setGptHasLoaded] = useState(false);
  const [gptHasInited, setGptHasInited] = useState(false);

  const createAdUnit = ({
    adUnit,
    elementId,
    parentAdUnit,
    sizes,
    targeting,
    onSlotLoaded,
  }) => {
    if (gtpNetworkId && adUnit && elementId) {
      const parentAdUnitPath = parentAdUnit ? `/${parentAdUnit}` : "";

      // create a map of all the sizes available for this ad
      // see: https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads
      const adSizes = Object.values(sizes).map((size) => size[1] || [0, 0]);

      // event fired when the ad iframe fires load event
      addEvent("slotOnload", onSlotLoaded);

      // define ad slot
      const slot = defineSlot(
        `${parentAdUnitPath}/${adUnit}`,
        adSizes,
        elementId,
        { targeting }
      );

      return slot;
    }
  };

  const registerAdSlot = useCallback(
    ({
      adUnit = "",
      elementId = "",
      onSlotLoaded = () => {},
      parentAdUnit = "",
      sizes = {},
      targeting = [],
    }) => {
      if (!gptHasLoaded) return;

      const adSlot = createAdUnit({
        adUnit,
        elementId,
        parentAdUnit,
        sizes,
        targeting,
        onSlotLoaded,
      });

      if (adSlot) {
        trace("registerAdSlot", adSlot);
        defineSizeMapping(adSlot, sizes);
        display(adSlot);
      }
    },
    [gptHasLoaded]
  );

  const registerPageTargeting = useCallback(
    (pageTargets) => {
      if (!gptHasLoaded) return;

      trace("registerPageTargets", pageTargets);

      if (typeof pageTargets === "object") {
        Object.keys(pageTargets).forEach((key) => {
          setTargeting(key, pageTargets[key]);
        });
      }
    },
    [gptHasLoaded]
  );

  const refreshAds = () => {
    trace("refreshAds");
    refresh();
  };

  const handleRouteChange = () => {
    trace("handleRouteChange");
    clearTargeting();
    destroySlots();
    setGptHasInited(false);
  };

  const handleRouteComplete = () => {
    trace("handleRouteComplete");
    setGptHasInited(true);
  };

  // router changes
  useRouteLoading({ handleRouteChange, handleRouteComplete });

  // config gtp when provider loads
  useEffect(() => {
    cmdPush(() => {
      // disable automatic display for ads
      // https://developers.google.com/publisher-tag/guides/control-ad-loading
      enableLazyLoad();
      disableInitialLoad();
      enableSingleRequest();
      setGptHasLoaded(true);
    });
  }, []);

  // initialize gpt hook when gpt is loaded
  useEffect(() => {
    if (gptHasLoaded) {
      cmdPush(() => setGptHasInited(true));
    }
  }, [gptHasLoaded]);

  // display ads when gpt hook is inited
  useEffect(() => {
    if (gptHasInited) {
      // refresh the registered ads slots
      refresh();
    }
  }, [gptHasInited]);

  return (
    <AdsContext.Provider
      value={{
        gptHasInited,
        registerAdSlot,
        registerPageTargeting,
        refreshAds,
      }}
    >
      {children}
    </AdsContext.Provider>
  );
};

const useAds = () => {
  const context = useContext(AdsContext);
  if (context === undefined) {
    throw new Error("useAds must be used within a AdsProvider");
  }
  return context;
};

export { AdsProvider, useAds };
